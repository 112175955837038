var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "text-h4" }, [
              _vm._v("\n        Engagement Comparison\n      ")
            ])
          ]),
          _c(
            "v-col",
            { staticClass: "pb-1", attrs: { cols: "12", sm: "4", lg: "3" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.widgets,
                  label: "Widgets",
                  "item-text": "text",
                  "item-value": "value",
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  multiple: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var index = ref.index
                      return [
                        index === 0
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.widgetsSelected.length) +
                                  " selected\n          "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.widgetsSelected,
                  callback: function($$v) {
                    _vm.widgetsSelected = $$v
                  },
                  expression: "widgetsSelected"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-4 mt-2" }),
      _c(
        "v-row",
        [
          _vm.showViewAsCustomer
            ? _c(
                "v-col",
                {
                  staticClass: "pb-1",
                  attrs: { cols: "12", sm: "4", lg: "3" }
                },
                [_c("ViewAsCustomer")],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "pb-1", attrs: { cols: "12", sm: "4", lg: "3" } },
            [
              _c(
                "v-menu",
                {
                  ref: "dateMenu",
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "pt-0",
                                  attrs: {
                                    value: _vm.dateRangeText(
                                      _vm.dashboardBase.dates
                                    ),
                                    label: "Month range",
                                    "prepend-icon": "mdi-calendar-range",
                                    readonly: "",
                                    "single-line": "",
                                    "hide-details": ""
                                  }
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dashboardBase.dateMenu,
                    callback: function($$v) {
                      _vm.$set(_vm.dashboardBase, "dateMenu", $$v)
                    },
                    expression: "dashboardBase.dateMenu"
                  }
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      range: "",
                      type: "month",
                      min: _vm.minMonth.local().format(),
                      max: _vm.currentMonth,
                      "show-current": ""
                    },
                    model: {
                      value: _vm.dashboardBase.dates,
                      callback: function($$v) {
                        _vm.$set(_vm.dashboardBase, "dates", $$v)
                      },
                      expression: "dashboardBase.dates"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "4", lg: "3" } }, [
            _c(
              "div",
              {
                staticClass: "text-h6 font-italic",
                style: [{ display: "flex", "justify-content": "center" }]
              },
              [_vm._v("\n        Compared to\n      ")]
            )
          ]),
          _c(
            "v-col",
            { staticClass: "pb-1", attrs: { cols: "4", lg: "3" } },
            [
              _c(
                "v-menu",
                {
                  ref: "dateMenu2",
                  staticStyle: { "padding-top": "0px" },
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "pt-0",
                                  attrs: {
                                    value: _vm.dateRangeText(
                                      _vm.dashboardComparison.dates
                                    ),
                                    label: "Month range",
                                    "prepend-icon": "mdi-calendar-range",
                                    readonly: "",
                                    "single-line": "",
                                    "hide-details": ""
                                  }
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dashboardComparison.dateMenu2,
                    callback: function($$v) {
                      _vm.$set(_vm.dashboardComparison, "dateMenu2", $$v)
                    },
                    expression: "dashboardComparison.dateMenu2"
                  }
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      range: "",
                      type: "month",
                      min: _vm.minMonth.local().format(),
                      max: _vm.currentMonth,
                      "show-current": ""
                    },
                    model: {
                      value: _vm.dashboardComparison.dates,
                      callback: function($$v) {
                        _vm.$set(_vm.dashboardComparison, "dates", $$v)
                      },
                      expression: "dashboardComparison.dates"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm.widgetsSelected.includes("Sent")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "6" } },
                [
                  _c("ComparisonBasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Sent"
                      }).text,
                      name: "Sent",
                      "mailing-service-method": "getReportSentWidget",
                      icon: "mdi-send-check",
                      "icon-two": "mdi-arrow-u-down-right-bold",
                      "icon-three": "mdi-arrow-u-down-right",
                      "info-description": "The number of sent emails.",
                      "info-description-two":
                        "Hard Bounce numbers (as a % of your sent emails).",
                      "info-description-three":
                        "Soft Bounce numbers (as a % of your sent emails).",
                      "name-description-two": "Hard Bounce",
                      "name-description-three": "Soft Bounce",
                      dates: _vm.dashboardBase.dates,
                      "comparison-dates": _vm.dashboardComparison.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("Received")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "6" } },
                [
                  _c("ComparisonBasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Received"
                      }).text,
                      name: "Received",
                      icon: "mdi-email-check-outline",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description":
                        "The number of recipients that received your emails (as a % of your sent emails).",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      "name-description-two": "Initial",
                      "name-description-three": "Resend",
                      dates: _vm.dashboardBase.dates,
                      "comparison-dates": _vm.dashboardComparison.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("Optouts")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "6" } },
                [
                  _c("ComparisonBasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Optouts"
                      }).text,
                      name: "Optouts",
                      icon: "mdi-email-alert-outline",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description":
                        "The number of opt-outs generated from your emails (as a % of your delivered emails).",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      "name-description-two": "Initial",
                      "name-description-three": "Resend",
                      dates: _vm.dashboardBase.dates,
                      "comparison-dates": _vm.dashboardComparison.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("Opens")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "6" } },
                [
                  _c("ComparisonBasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Opens"
                      }).text,
                      name: "Opens",
                      icon: "mdi-email-open-outline",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description":
                        "The number of recipients that opened your emails (as a % of your delivered emails).",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      "name-description-two": "Initial",
                      "name-description-three": "Resend",
                      dates: _vm.dashboardBase.dates,
                      "comparison-dates": _vm.dashboardComparison.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("Clicks")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "6" } },
                [
                  _c("ComparisonBasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Clicks"
                      }).text,
                      name: "Clicks",
                      icon: "mdi-cursor-default-click-outline",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description":
                        "The number of recipients that clicked on a link in your emails (as a % of your delivered emails).",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      "name-description-two": "Initial",
                      "name-description-three": "Resend",
                      dates: _vm.dashboardBase.dates,
                      "comparison-dates": _vm.dashboardComparison.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("Replies")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "6" } },
                [
                  _c("ComparisonBasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Replies"
                      }).text,
                      name: "Replies",
                      "info-description":
                        "The number of replies from your emails (as a % of your delivered emails).",
                      icon: "mdi-reply",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      "name-description-two": "Initial",
                      "name-description-three": "Resend",
                      dates: _vm.dashboardBase.dates,
                      "comparison-dates": _vm.dashboardComparison.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("ReadEng")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "6" } },
                [
                  _c("ComparisonBasicMetricsWidget", {
                    attrs: {
                      "display-name": "Average Read Engagement",
                      name: "ReadEng",
                      icon: "mdi-book-open-page-variant-outline",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description":
                        "The average number of seconds recipients spent reading your emails.",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      "name-description-two": "Initial",
                      "name-description-three": "Resend",
                      "mailing-service-method": "getReportAvgReadSecsWidget",
                      "appended-string": " s",
                      "hide-counts": "",
                      "is-seconds": "",
                      dates: _vm.dashboardBase.dates,
                      "comparison-dates": _vm.dashboardComparison.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("ReadEnv")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "6" } },
                [
                  _c("ComparisonStringMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "ReadEnv"
                      }).text,
                      name: "ReadEnv",
                      "mailing-service-method": "getReportReadEnvWidget",
                      "info-description":
                        "The most popular reading environment opened by your recipients (as a % of all recorded reading environments).",
                      icon: "mdi-numeric-1-box-outline",
                      "icon-two": "mdi-numeric-2-box-outline",
                      "icon-three": "mdi-numeric-3-box-outline",
                      "info-description-two":
                        "2nd most popular reading environment.",
                      "info-description-three":
                        "3rd most popular reading environment.",
                      dates: _vm.dashboardBase.dates,
                      "comparison-dates": _vm.dashboardComparison.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("MailClients")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "6" } },
                [
                  _c("ComparisonStringMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "MailClients"
                      }).text,
                      name: "MailClients",
                      "mailing-service-method": "getReportMailClientsWidget",
                      "info-description":
                        "The most popular mail client opened by your recipients (as a % of all recorded mail clients).",
                      icon: "mdi-at",
                      "icon-two": "mdi-numeric-2-box-outline",
                      "icon-three": "mdi-numeric-3-box-outline",
                      "info-description-two": "2nd most popular mail client.",
                      "info-description-three": "3rd most popular mail client.",
                      dates: _vm.dashboardBase.dates,
                      "comparison-dates": _vm.dashboardComparison.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { id: "detailSection", cols: "12" } },
            [
              _vm.showDetails
                ? _c(
                    "v-row",
                    { attrs: { align: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-0" },
                        [_c("v-divider", { staticClass: "my-2" })],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pr-0 pl-2", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: { icon: "" },
                                              on: { click: _vm.closeDetails }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "tertiary" } },
                                              [
                                                _vm._v(
                                                  "\n                  mdi-close\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2348375109
                              )
                            },
                            [_c("span", [_vm._v("Close Details")])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "6" } },
                    [
                      _vm.showDetails &&
                      !_vm.detailsLoading &&
                      (_vm.dashboardBase.resultDetailsChart ||
                        _vm.dashboardComparison.resultDetailsChart)
                        ? _c("horizontal-barChart", {
                            attrs: {
                              "chart-data":
                                _vm.dashboardBase.resultDetailsChart,
                              options: _vm.getChartOptionsBar(
                                _vm.showDetailsMetric,
                                _vm.dashboardBase.dates
                              ),
                              height: _vm.chartHeight
                            }
                          })
                        : _vm.showDetails
                        ? _c("v-skeleton-loader", {
                            attrs: { type: "image@2" }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "6" } },
                    [
                      _vm.showDetails &&
                      !_vm.detailsLoading &&
                      (_vm.dashboardBase.resultDetailsChart ||
                        _vm.dashboardComparison.resultDetailsChart)
                        ? _c("horizontal-barChart", {
                            attrs: {
                              "chart-data":
                                _vm.dashboardComparison.resultDetailsChart,
                              options: _vm.getChartOptionsBar(
                                _vm.showDetailsMetric,
                                _vm.dashboardComparison.dates
                              ),
                              height: _vm.chartHeight
                            }
                          })
                        : _vm.showDetails
                        ? _c("v-skeleton-loader", {
                            attrs: { type: "image@2" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-4 mt-2" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }