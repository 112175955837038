<template>
  <div>
    <v-row>
      <v-col>
        <div class="text-h4">
          Engagement Comparison
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        lg="3"
        class="pb-1"
      >
        <v-select
          v-model="widgetsSelected"
          :items="widgets"
          label="Widgets"
          item-text="text"
          item-value="value"
          dense
          hide-details
          outlined
          multiple
        >
          <template v-slot:selection="{ index }">
            <span
              v-if="index === 0"
            >
              {{ widgetsSelected.length }} selected
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-divider class="mb-4 mt-2" />
    <v-row>
      <v-col
        v-if="showViewAsCustomer"
        cols="12"
        sm="4"
        lg="3"
        class="pb-1"
      >
        <ViewAsCustomer />
      </v-col>
      <v-col
        cols="12"
        sm="4"
        lg="3"
        class="pb-1"
      >
        <v-menu
          ref="dateMenu"
          v-model="dashboardBase.dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText(dashboardBase.dates)"
              class="pt-0"
              label="Month range"
              prepend-icon="mdi-calendar-range"
              readonly
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dashboardBase.dates"
            range
            type="month"
            :min="minMonth.local().format()"
            :max="currentMonth"
            show-current
          />
        </v-menu>
      </v-col>
      <v-col
        cols="4"
        lg="3"
      >
        <div
          class="text-h6 font-italic"
          :style="[{ 'display': 'flex', 'justify-content': 'center' }]"
        >
          Compared to
        </div>
      </v-col>
      <v-col
        cols="4"
        lg="3"
        class="pb-1"
      >
        <v-menu
          ref="dateMenu2"
          v-model="dashboardComparison.dateMenu2"
          :close-on-content-click="false"
          style="padding-top: 0px"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText(dashboardComparison.dates)"
              class="pt-0"
              label="Month range"
              prepend-icon="mdi-calendar-range"
              readonly
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dashboardComparison.dates"
            range
            type="month"
            :min="minMonth.local().format()"
            :max="currentMonth"
            show-current
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="widgetsSelected.includes('Sent')"
        cols="12"
        md="6"
        lg="6"
      >
        <ComparisonBasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Sent').text"
          name="Sent"
          mailing-service-method="getReportSentWidget"
          icon="mdi-send-check"
          icon-two="mdi-arrow-u-down-right-bold"
          icon-three="mdi-arrow-u-down-right"
          info-description="The number of sent emails."
          info-description-two="Hard Bounce numbers (as a % of your sent emails)."
          info-description-three="Soft Bounce numbers (as a % of your sent emails)."
          name-description-two="Hard Bounce"
          name-description-three="Soft Bounce"
          :dates="dashboardBase.dates"
          :comparison-dates="dashboardComparison.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('Received')"
        cols="12"
        md="6"
        lg="6"
      >
        <ComparisonBasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Received').text"
          name="Received"
          icon="mdi-email-check-outline"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description="The number of recipients that received your emails (as a % of your sent emails)."
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          name-description-two="Initial"
          name-description-three="Resend"
          :dates="dashboardBase.dates"
          :comparison-dates="dashboardComparison.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('Optouts')"
        cols="12"
        md="6"
        lg="6"
      >
        <ComparisonBasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Optouts').text"
          name="Optouts"
          icon="mdi-email-alert-outline"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description="The number of opt-outs generated from your emails (as a % of your delivered emails)."
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          name-description-two="Initial"
          name-description-three="Resend"
          :dates="dashboardBase.dates"
          :comparison-dates="dashboardComparison.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('Opens')"
        cols="12"
        md="6"
        lg="6"
      >
        <ComparisonBasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Opens').text"
          name="Opens"
          icon="mdi-email-open-outline"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description="The number of recipients that opened your emails (as a % of your delivered emails)."
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          name-description-two="Initial"
          name-description-three="Resend"
          :dates="dashboardBase.dates"
          :comparison-dates="dashboardComparison.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('Clicks')"
        cols="12"
        md="6"
        lg="6"
      >
        <ComparisonBasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Clicks').text"
          name="Clicks"
          icon="mdi-cursor-default-click-outline"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description="The number of recipients that clicked on a link in your emails (as a % of your delivered emails)."
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          name-description-two="Initial"
          name-description-three="Resend"
          :dates="dashboardBase.dates"
          :comparison-dates="dashboardComparison.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('Replies')"
        cols="12"
        md="6"
        lg="6"
      >
        <ComparisonBasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Replies').text"
          name="Replies"
          info-description="The number of replies from your emails (as a % of your delivered emails)."
          icon="mdi-reply"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          name-description-two="Initial"
          name-description-three="Resend"
          :dates="dashboardBase.dates"
          :comparison-dates="dashboardComparison.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('ReadEng')"
        cols="12"
        md="6"
        lg="6"
      >
        <ComparisonBasicMetricsWidget
          display-name="Average Read Engagement"
          name="ReadEng"
          icon="mdi-book-open-page-variant-outline"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description="The average number of seconds recipients spent reading your emails."
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          name-description-two="Initial"
          name-description-three="Resend"
          mailing-service-method="getReportAvgReadSecsWidget"
          appended-string=" s"
          hide-counts
          is-seconds
          :dates="dashboardBase.dates"
          :comparison-dates="dashboardComparison.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('ReadEnv')"
        cols="12"
        md="6"
        lg="6"
      >
        <ComparisonStringMetricsWidget
          :display-name="widgets.find(x => x.value === 'ReadEnv').text"
          name="ReadEnv"
          mailing-service-method="getReportReadEnvWidget"
          info-description="The most popular reading environment opened by your recipients (as a % of all recorded reading environments)."
          icon="mdi-numeric-1-box-outline"
          icon-two="mdi-numeric-2-box-outline"
          icon-three="mdi-numeric-3-box-outline"
          info-description-two="2nd most popular reading environment."
          info-description-three="3rd most popular reading environment."
          :dates="dashboardBase.dates"
          :comparison-dates="dashboardComparison.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('MailClients')"
        cols="12"
        md="6"
        lg="6"
      >
        <ComparisonStringMetricsWidget
          :display-name="widgets.find(x => x.value === 'MailClients').text"
          name="MailClients"
          mailing-service-method="getReportMailClientsWidget"
          info-description="The most popular mail client opened by your recipients (as a % of all recorded mail clients)."
          icon="mdi-at"
          icon-two="mdi-numeric-2-box-outline"
          icon-three="mdi-numeric-3-box-outline"
          info-description-two="2nd most popular mail client."
          info-description-three="3rd most popular mail client."
          :dates="dashboardBase.dates"
          :comparison-dates="dashboardComparison.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        id="detailSection"
        cols="12"
      >
        <v-row
          v-if="showDetails"
          align="center"
          no-gutters
        >
          <v-col
            class="px-0"
          >
            <v-divider
              class="my-2"
            />
          </v-col>
          <v-col
            class="pr-0 pl-2"
            cols="auto"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="closeDetails"
                >
                  <v-icon color="tertiary">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close Details</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="6"
          >
            <horizontal-barChart
              v-if="showDetails && !detailsLoading && (dashboardBase.resultDetailsChart || dashboardComparison.resultDetailsChart)"
              :chart-data="dashboardBase.resultDetailsChart"
              :options="getChartOptionsBar(showDetailsMetric, dashboardBase.dates)"
              :height="chartHeight"
            />
            <v-skeleton-loader
              v-else-if="showDetails"
              type="image@2"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
          >
            <horizontal-barChart
              v-if="showDetails && !detailsLoading && (dashboardBase.resultDetailsChart || dashboardComparison.resultDetailsChart)"
              :chart-data="dashboardComparison.resultDetailsChart"
              :options="getChartOptionsBar(showDetailsMetric, dashboardComparison.dates)"
              :height="chartHeight"
            />
            <v-skeleton-loader
              v-else-if="showDetails"
              type="image@2"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="mb-4 mt-2" />
  </div>
</template>

<script>
import {
  inputMonthFormat,
  displayMonthFormat,
  inputDateFormat
} from '@/shared/constants'
import mutationTypes from '@/store/mutation-types'
import { mapGetters } from 'vuex'
import claims from '@/shared/models/auth/claims'
import { mailingService } from '@/shared/services'
import { downloadFileMixin } from '@/shared/mixins/general'
import moment from 'moment'

export default {

  name: 'DashboardCompare',

  components: {
    ViewAsCustomer: () => import('@/views/pages/components/user-state/ViewAsCustomer'),
    ComparisonBasicMetricsWidget: () => import('@/views/pages/reports/dashboard-widgets/ComparisonBasicMetricsWidget.vue'),
    ComparisonStringMetricsWidget: () => import('@/views/pages/reports/dashboard-widgets/ComparisonStringMetricsWidget.vue'),
    HorizontalBarChart: () => import('@/views/pages/components/charts/HorizontalBarChart')
  },

  mixins: [downloadFileMixin],

  data () {
    return {
      widgets: [
        {
          text: 'Delivered',
          value: 'Received',
          detailMethod: 'getReportMetricWidgetDetails',
          // chartScaleMin: 50,
          // chartScaleMax: 100,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        {
          text: 'Opens',
          value: 'Opens',
          detailMethod: 'getReportMetricWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 50,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        {
          text: 'Clicks',
          value: 'Clicks',
          detailMethod: 'getReportMetricWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 30,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        {
          text: 'Average Read Engagement',
          value: 'ReadEng',
          detailMethod: 'getReportAvgReadSecsWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 60,
          xAxisLabel: 'Month/Year',
          append: 's',
          precision: 1
        },
        {
          text: 'Sent/Bounces',
          value: 'Sent',
          detailMethod: 'getReportSentWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 50,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        {
          text: 'Opt-outs',
          value: 'Optouts',
          detailMethod: 'getReportMetricWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 5,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        // {
        //   text: 'Mail Blocks',
        //   value: 'MailBlocks',
        //   detailMethod: 'getReportMetricWidgetDetails',
        //   // chartScaleMin: 0,
        //   // chartScaleMax: 50,
        //   xAxisLabel: 'Month/Year',
        //   append: '%',
        //   precision: 2
        // },
        {
          text: 'Replies',
          value: 'Replies',
          detailMethod: 'getReportMetricWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 5,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        {
          text: 'Mail Clients',
          value: 'MailClients',
          detailMethod: 'getReportMailClientsWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 100,
          xAxisLabel: 'Mail Client',
          append: '%',
          precision: 2
        },
        {
          text: 'Read Environments',
          value: 'ReadEnv',
          detailMethod: 'getReportReadEnvWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 100,
          xAxisLabel: 'Device',
          append: '%',
          precision: 2
        }
      ],
      dashboardBase: {
        name: 'Base',
        dateMenu: false,
        dates: [
          moment().subtract(1, 'month').local().format(inputMonthFormat),
          moment().local().format(inputMonthFormat)
        ],
        detailsLoading: false,
        resultDetailsChart: null
      },
      dashboardComparison: {
        name: 'Comparison',
        dateMenu2: false,
        dates: [
          moment().subtract(1, 'month').local().format(inputMonthFormat),
          moment().local().format(inputMonthFormat)
        ],
        detailsLoading: false,
        resultDetailsChart: null
      },
      currentMonth: moment().local().format(),
      unsubscribe: null,
      chartHeight: 700,
      showDetails: false,
      showDetailsMetric: 'Delivered',
      detailMetric: 'Received',
      detailsLoading: false,
      biDescription: 'Broadcast Intelligence Report',
      biIcon: 'mdi-chart-areaspline',
      gradient: null,
      gradient2: null
    }
  },

  computed: {
    ...mapGetters('auth', ['hasClaimKV']),
    widgetsSelected: {
      get () {
        return this.$store.getters.reportWidgets()
      },
      set (value) {
        this.$store.dispatch('setReportWidgets', value)
      }
    },
    selectedMetricDetail () {
      return this.widgets.find(x => x.value === this.detailMetric)
    },
    showViewAsCustomer () {
      return this.hasClaimKV(claims.MMS_TEBT)
    },
    minMonth () {
      return moment().subtract(24, 'months')
    },
    comparisonValueSelected () {
      return this.comparisonValue !== null && this.comparisonValue !== undefined
    }
  },

  watch: {
    'dashboardBase.dates' (newValue, oldValue) {
      if (newValue && newValue.length === 2) {
        if (moment(newValue[0]).isAfter(moment(newValue[1]))) {
          this.dashboardBase.dates = [
            newValue[1],
            newValue[0]
          ]
          return
        }
        if (this.showDetails) this.loadViewDetails(this.dashboardBase)
      }
    },
    'dashboardComparison.dates': {
      handler (newValue, oldValue) {
        if (newValue && newValue.length === 2) {
          if (moment(newValue[0]).isAfter(moment(newValue[1]))) {
            this.dashboardComparison.dates = [
              newValue[1],
              newValue[0]
            ]
            return
          }
          if (this.showDetails) this.loadViewDetails(this.dashboardComparison)
        }
      }
    },
    compareValue (newValue, oldValue) {
      if (newValue) {
        this.loadViewDetails(this.dashboardComparison)
      }
    }
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type) && this.showDetails) {
        this.loadViewDetails(this.dashboardBase)
        this.loadViewDetails(this.dashboardComparison)
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  methods: {
    dateRangeText (dates) {
      if (!dates || dates.length !== 2) return ''
      if (moment(dates[0]).isSame(moment(dates[1]), 'month')) return moment(dates[0]).format(displayMonthFormat)
      return dates.map(x => moment(x).format(displayMonthFormat)).join(' - ')
    },
    closeDetails () {
      this.showDetails = false
      this.$vuetify.goTo(0, { offset: 0, easing: 'easeInCubic', duration: 400 })
    },
    async viewDetails (metric) {
      if (this.dashboardBase.dates.length !== 2 || this.$store.getters['isSimulatedMMS']()) return
      this.detailMetric = metric
      this.showDetailsMetric = this.widgets.find(x => x.value === metric).text
      this.$vuetify.goTo('#detailSection', { easing: 'easeInCubic', duration: 500 })
      await this.loadViewDetails(this.dashboardBase)
      await this.loadViewDetails(this.dashboardComparison)
    },
    async loadViewDetails (dashboard) {
      if (this.dashboardBase.dates.length !== 2 || this.$store.getters['isSimulatedMMS']()) return
      var _this = this
      dashboard.detailsLoading = true
      this.showDetails = true
      await mailingService[this.selectedMetricDetail.detailMethod]({
        customerNowwId: this.$store.getters['simulatedCustomerNowwId'](),
        metricName: this.detailMetric,
        startDate: moment(dashboard.dates[0]).startOf('month').format(inputDateFormat),
        endDate: moment(dashboard.dates[1]).endOf('month').format(inputDateFormat)
      }).then(function (resp) {
        if (resp.errorResponse) {
          _this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        } else {
          dashboard.resultDetailsChart = resp
        }
      })
        .finally(() => {
          dashboard.detailsLoading = false
        })
    },
    getChartData (data, comparisonData) {
      var chartData = {
        labels: [],
        datasets: []
      }
      chartData.labels = data.labels

      var dataset = data.datasets.map(x => { return { ...x, borderColor: '#9575CD', backgroundColor: 'transparent', label: 'My Data' } })
      if (comparisonData !== null) {
        var compareDataset = comparisonData.datasets.map(x => { return { ...x, borderColor: '#A5D6A7', backgroundColor: 'transparent', label: 'Compared To' } })
      }
      if (compareDataset !== undefined && compareDataset.length > 0) {
        Array.prototype.push.apply(dataset, compareDataset)
        chartData.datasets = dataset
      } else {
        chartData.datasets = dataset
      }
      return chartData
    },
    getChartOptionsBar (title, dates) {
      var append = this.selectedMetricDetail.append
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: `${title} from ${this.dateRangeText(dates)}`
        },
        legend: {
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: this.selectedMetricDetail.xAxisLabel
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              callback: function (value, index, values) {
                return `${value}`
              },
              min: 0
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              callback: function (value, index, values) {
                return `${value}${append}`
              }
            }
          }]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItem, data) => {
              return `${tooltipItem.value}${append}`
            }
          }
        },
        plugins: {
          legend: {
            position: 'right'
          }
        }
      }
    }
  }

}
</script>

<style lang="scss">
.v-card__title {
  word-break: normal;
}
</style>
